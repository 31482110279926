<!-- eslint-disable vue/html-self-closing -->
<template>
  <div v-if="(loggedUser.roles.includes('super-admin') && group.id == 50) || group.id != 50">
    <h5>
      {{ group.name }}
      <span
        v-if="canEdit"
        :style="{'display': edit ? 'none' : ''}"
        @click="editGroup(true)"
      >
        <i
          class="me-50 ms-1"
          data-feather="edit"
        />
      </span>
      <span
        v-if="canEdit"
        :style="{'display': !edit ? 'none' : ''}"
        @click="editGroup(false)"
      >
        <i
          class="me-50 ms-1 text-success"
          data-feather="save"
        />
      </span>
    </h5>
    <div
      v-if="loading"
      class="loading custom-loading position-relative"
    >
      <div class="effect-1 effects" />
      <div class="effect-2 effects" />
      <div class="effect-3 effects" />
    </div>
    <template v-else>
      <template v-if="!edit">
        <div
          v-if="group.type !== 'list' && group.type !== 'list-mail'"
          class="row"
        >
          <div
            v-for="parameter in parameters"
            :key="parameter.id"
            :class="[
              !parameter.column_size || parameter.column_size === 6
                ? 'col-md-6'
                : 'col-md-12',
            ]"
          >
            <p
              v-if="parameter.answer && parameter.answer.value"
              class="input-content-text"
            >
              {{ parameter.name }}
            </p>

            <div v-if="parameter.answer && parameter.answer.value">
              <!-- FILE -->
              <div
                v-if="parameter.type.name == 'file'"
                class="text-content"
              >
                <a
                  href="javascript:;"
                  @click="openFile(parameter.answer.id)"
                >{{ parameter.answer.value }}</a>
              </div>
              <!-- END FILE -->

              <!-- PUBLICATION -->
              <div
                v-else-if="parameter.type.name == 'publication'"
                class="text-content"
              >
                <div
                  v-html="JSON.parse(parameter.answer.value).publication"
                ></div>

                <div class="mt-1">
                  <strong>Justification: </strong>{{ JSON.parse(parameter.answer.value).append }}
                </div>
              </div>
              <!-- END PUBLICATION -->

              <!-- INSTITUTION -->
              <div
                v-else-if="parameter.type.name == 'institution'"
                class="text-content"
              >
                {{ Number.isInteger(JSON.parse(parameter.answer.value).value) ? parameter.values.filter(obj => obj.id == JSON.parse(parameter.answer.value).value)[0].default_value : JSON.parse(parameter.answer.value).value }}   -
                <span
                  id="status2"
                  :class="{'d-none': JSON.parse(parameter.answer.value).status != 1}"
                  class="text-danger"
                ><i data-feather="coffee"></i> Letter pending</span>
                <span
                  id="status2"
                  :class="{'d-none': JSON.parse(parameter.answer.value).status != 2}"
                  class="text-success"
                >
                  <i data-feather="check-circle"></i>
                  <a
                    v-if="canEdit"
                    href="#"
                    @click="checkInstitutionLetter(parameter.answer.id)"
                  >
                    Letter uploaded
                  </a>
                  <template v-else>
                    Letter uploaded
                  </template>
                </span>
              </div>
              <!-- END INSTITUTION -->

              <!-- ERC -->
              <div
                v-else-if="parameter.type.name == 'erc'"
                class="text-content"
              >
                <div v-html="getAnswer(parameter, 1)" />
              </div>
              <!-- END ERC -->
              <!-- SELECT -->
              <div
                v-else-if="parameter.type.name == 'select'"
                class="text-content"
              >
                {{ parameter.values.filter(obj => obj.id == parameter.answer.value)[0].default_value }}
              </div>
              <!-- END SELECT -->

              <!-- ALL THE OTHERS -->
              <div
                v-else
                class="text-content"
                v-html="parameter.answer.value"
              >
              </div>
            </div>
            <!-- END ALL THE OTHERS -->
          </div>

        </div>
        <div v-else>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th
                    v-for="parameter in parameters"
                    :key="parameter.id"
                  >
                    {{ parameter.name }}
                  </th>
                  <th
                    v-if="group.type == 'list-mail'"
                    class="th-table-actions"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="index in entriesNumber"
                  :key="index"
                >
                  <td
                    v-for="parameter in parameters"
                    :key="parameter.id"
                  >
                    <span v-html="getAnswer(parameter, index)"></span>
                  </td>
                  <td
                    v-if="group.type == 'list-mail'"
                    class="text-end"
                  >
                    <span
                      v-if="group.status && getStatus(group.status.status, index) != 1"
                      class="text-danger"
                    >
                      Pending letter
                    </span>
                    <span
                      v-else
                      class="text-success"
                    >
                      <a
                        v-if="canEdit"
                        href="#"
                        @click="checkLetter(index)"
                      >
                        Letter received
                      </a>
                      <template v-else>
                        Letter received
                      </template>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          v-if="(group.type === 'list' || group.type === 'list-mail') && parameters"
          class="row"
        >
          <ParameterList
            :group-id="group.related_group_id"
            :section-id="sectionId"
            :headers="parameters"
            :mail="group.type === 'list-mail'"
            :status="group.status"
            :save-answer="true"
            :user-id="userId"
          />
        </div>
        <div
          v-else
          class="card-body"
          style="padding-top: 0px;"
        >
          <div
            class="row"
          >
            <Parameter
              v-for="(parameter) in parameters"
              :key="parameter.id"
              :parameter="parameter"
              :answer="parameter.answer"
              :group-id="group.id"
              :section-id="sectionId"
              :save-answer="true"
              :user-id="userId"
            />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import Parameter from '../Parameter.vue'
import ParameterList from '../ParameterList.vue'

export default {
  components: {
    ParameterList,
    Parameter,
  },
  props: {
    group: { type: Object, required: true },
    sectionId: { type: Number, required: true },
    userId: { type: Number, required: false, default: null },
  },
  data() {
    return {
      parameters: {},
      entriesNumber: 0,
      loading: true,
      groupUrl: Vue.prototype.$groupUrlCv,
      edit: false,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      filters: 'filters/filters',
      load: 'preview/load',
      user: 'auth/user',
      loggedUser: 'auth/admin',
    }),
    canEdit() {
      return window.location.href.includes('/intranet') && this.loggedUser.roles.includes('super-admin')
    },
    isIntranet() {
      return window.location.href.includes('/intranet')
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    checkLetter(index) {
      const { answer } = this.parameters[0]
      window.open(`${Vue.prototype.$groupUrl}/parameter/check-letter/${this.userId}/${this.$route.params.convo_id ?? this.convo.id}/${answer.group_id}/${answer.convo_section_id}/${index}?token=${localStorage.getItem('accessTokenAdmin')}`, '_blank')
    },
    checkInstitutionLetter(answer) {
      window.open(`${Vue.prototype.$groupUrl}/parameter/check-institution-file/${answer}?token=${localStorage.getItem('accessTokenAdmin')}`, '_blank')
    },
    async fetch() {
      await this.$store.dispatch('convoGroup/fetchParameters', [this.group.id, this.convo.id, this.sectionId, this.userId])

      this.parameters = this.$store.getters['convoGroup/parameters']

      if (this.group.type === 'list' || this.group.type === 'list-mail') {
        let maxLength = 0
        this.parameters.forEach(parameter => {
          if (parameter.answer) {
            const { length } = Object.keys(JSON.parse(parameter.answer.value))
            if (length > maxLength) maxLength = length
          }
        })

        this.entriesNumber = maxLength
      }

      this.loading = false
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 50)
    },
    getAnswer(parameter, index) {
      if (parameter.answer && parameter.answer.value) {
        if (parameter.type.name === 'select' || parameter.type.name === 'radio' || (parameter.type.name === 'combo-box' && Number.isInteger(JSON.parse(parameter.answer.value)[index - 1]))) {
          let name = ''
          parameter.values.forEach(item => {
            if (item.id == JSON.parse(parameter.answer.value)[index - 1]) {
              name = item.default_value
            }
          })

          return name
        }

        if (parameter.type.name === 'erc') {
          let firstLevel = '-'
          let secondLevel = '-'
          let thirdLevel = '-'

          let lvlOne = '-'
          let lvlTwo = '-'
          let lvlThree = '-'

          if (JSON.parse(parameter.answer.value)[index - 1][0]) {
            firstLevel = parameter.values.findIndex(e => e.id === JSON.parse(parameter.answer.value)[index - 1][0])

            if (firstLevel >= 0) {
              secondLevel = parameter.values[firstLevel].childrens.findIndex(e => e.id === JSON.parse(parameter.answer.value)[index - 1][1])
            }

            if (secondLevel >= 0) {
              thirdLevel = parameter.values[firstLevel].childrens[secondLevel].childrens.findIndex(e => e.id === JSON.parse(parameter.answer.value)[index - 1][2])
            }
          } else {
            firstLevel = parameter.values.findIndex(e => e.id === JSON.parse(parameter.answer.value)[index - 1])

            if (firstLevel >= 0) {
              secondLevel = parameter.values[firstLevel].childrens.findIndex(e => e.id === JSON.parse(parameter.answer.value)[index])
            }

            if (secondLevel >= 0) {
              thirdLevel = parameter.values[firstLevel].childrens[secondLevel].childrens.findIndex(e => e.id === JSON.parse(parameter.answer.value)[index + 1])
            }
          }

          lvlOne = parameter.values[firstLevel] ? parameter.values[firstLevel].default_value : '-'
          lvlTwo = parameter.values[firstLevel] && parameter.values[firstLevel].childrens[secondLevel] ? parameter.values[firstLevel].childrens[secondLevel].name : '-'
          lvlThree = parameter.values[firstLevel] && parameter.values[firstLevel].childrens[secondLevel] && parameter.values[firstLevel].childrens[secondLevel].childrens[thirdLevel] ? parameter.values[firstLevel].childrens[secondLevel].childrens[thirdLevel].name : '-'
          let show = ''

          if (lvlThree !== '-') {
            show = lvlThree
          } else if (lvlTwo !== '-') {
            show = lvlTwo
          } else {
            show = lvlOne
          }

          return `
            <p class="mb-0"><strong>${show}</strong></p>
          `
        }
        return JSON.parse(parameter.answer.value)[index - 1]
      }
      return '-'
    },
    openFile(id) {
      let token = ''
      if (window.location.href.includes('/intranet')) {
        token = localStorage.getItem('accessTokenAdmin')
      } else {
        token = localStorage.getItem('accessToken')
      }

      window.open(`${this.groupUrl}/${id}?token=${token}`, '_blank', '')
    },
    getStatus(status, index) {
      return JSON.parse(status)[index - 1]
    },
    editGroup(edit) {
      if (this.canEdit) {
        this.edit = edit
        if (!edit) {
          Vue.swal({
            title: 'Do you want to confirm the changes?',
            html: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Yes',
          }).then(result => {
            if (result.isConfirmed) {
              this.loading = true
              this.fetch()
              this.$store.dispatch('convo/fetchApplicants', { id: this.$route.params.id, status: null, candidate: this.filters.candidate })
            }
          })
        }
      }

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 50)
    },
  },
}

</script>
