<!-- eslint-disable vue/html-self-closing -->
<template>
  <div
    class="card"
    :class="{'border-success': section.status == 2, 'border-danger': section.status == 1 || section.status == 0}"
  >
    <div
      v-b-toggle="`collapseSection-${section.id}`"
      class="card-header"
      @click="loadGroups()"
    >
      <h4 class="card-title">
        <a data-action="collapse">{{ (index > 9 ? index : '0' + index) + '. ' + section.title }}</a>
      </h4>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li
            v-if="section.status == 2"
            class="text-success"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="All fields are filled"
          >
            <i data-feather="check-circle"></i>
          </li>
          <li
            v-if="section.status == 1 || section.status == 0"
            class="text-danger"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="There are empty fields"
          >
            <i data-feather="alert-circle"></i>
          </li>
          <li>
            <a
              data-action="collapse"
            ><i data-feather="chevron-down"></i></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseSection-${section.id}`"
      :visible="!closed"
    >
      <div
        v-if="!loading"
        class="card-body preview__card-body"
      >
        <PreviewGroup
          v-for="group in groups"
          :key="group.id"
          :group="group"
          :user-id="userId"
          :section-id="section.id"
        />
      </div>
      <div
        v-if="loading"
        class="card-body preview__card-body mb-2"
      >
        <div
          class="loading"
        >
          <div class="effect-1 effects" />
          <div class="effect-2 effects" />
          <div class="effect-3 effects" />
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import PreviewGroup from './PreviewGroup.vue'

export default {
  components: {
    BCollapse,
    PreviewGroup,
  },
  props: {
    section: { type: Object, required: true },
    index: { type: Number, required: true },
    userId: { type: Number, required: false, default: null },
  },
  data() {
    return {
      groups: {},
      open: false,
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      closed: 'preview/closed',
      load: 'preview/load',
      convo: 'convo/convo',
    }),
    convoId() {
      return this.convo ? this.convo.id : this.$route.query.convo
    },
  },
  watch: {
    load() {
      this.fetch()
    },
  },
  methods: {
    async loadGroups() {
      this.open = !this.open

      if (this.open) {
        this.fetch()
      }
    },
    async fetch() {
      if (Object.keys(this.groups) && Object.keys(this.groups).length === 0) {
        await this.$store.dispatch('convoSection/fetchGroupsByUser', [this.section.id, this.userId, this.convoId])
        this.groups = this.$store.getters['convoSection/groups']
        this.loading = false
      }
    },
  },
}
</script>
