<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <label
      class="form-label"
      for="select2-institution"
    >{{ parameter.name }}</label>
    <a
      href="#"
      style="font-size: 1rem;display: inline;float: inherit;margin-left: 5px;"
      @click="showInput(false)"
    >Click here if the desired option is not in the dropdown</a>
    <select
      v-if="listed"
      v-model="value"
      class="form-control"
      :disabled="!convo.open_applicants"
      :value="value"
      @change="submitAnswer()"
    >
      <option
        v-for="option in parameter.values"
        :key="option.id"
        :value="option.id"
      >
        {{ option.default_value }}
      </option>
    </select>
    <!-- este input sale tras hacer clic en el enlace de mi institucion no está en el listado. cambiar el select por el input group -->
    <div
      v-if="!listed"
      class="input-group input-group-merge mb-2"
    >
      <input
        v-model="value"
        class="form-control"
        type="tel"
        :placeholder="
          parameter.attributes ? parameter.attributes.placeholder : ''
        "
        @change="submitAnswer()"
      />
      <a
        class="input-group-text cursor-pointer"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Cancel"
        @click="showInput(true)"
      ><i data-feather="x"></i></a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    parameter: { type: Object, required: true },
    answer: {},
  },
  data() {
    return {
      listed: true,
      value: '',
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
    }),
  },
  watch: {
    // whenever question changes, this function will run
    answer() {
      if (this.answer && this.answer.value) {
        this.value = this.answer.value

        if (!this.isNumeric(this.value)) {
          this.listed = false
        }
      } else {
        this.value = null
        this.listed = true
      }
    },
  },
  mounted() {
    this.replaceIcons()
  },
  methods: {
    submitAnswer() {
      this.$emit('submitAnswer', this.value)
    },
    replaceIcons() {
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    showInput(show) {
      this.listed = show
      this.value = ''
      this.replaceIcons()
    },
    isNumeric(str) {
      if (typeof str === 'number') return true
      if (typeof str !== 'string') return false // we only process strings!
      return !Number.isNaN(str) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         && !Number.isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
  },
}
</script>
